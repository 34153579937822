import React from 'react'
import hero1 from '../Assets/Hero/hero 1.jpg'
import hero2 from '../Assets/Hero/hero2.jpg'
import hero3 from '../Assets/Hero/hero3.jpg'

const Hero = () => {
  return (
    <div className='mt-1'>
  <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={hero1} className="d-block w-100" height={650} alt="Her0_1" />
    </div>
    <div className="carousel-item">
      <img src={hero2} className="d-block w-100" height={650} alt="hero_2" />
    </div>
    <div className="carousel-item">
      <img src={hero3} className="d-block w-100" height={650} alt="hero_3" />
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

    </div>
  )
}

export default Hero
